const initPaypal = async (payload: any, initPaypalData: any): Promise<any> => {
    if (payload && initPaypalData) {
        const url = initPaypalData.pay_form?.script_url;

        if (!url) {
            throw new Error('URL for PayPal script is missing');
        }

        const buttonWrapperDiv = document.getElementById('paypal-button');

        if (!url || !buttonWrapperDiv) {
            throw Error('url && buttonWrapper required for init PayPal button');
        }

        insertScript(url);

        if (buttonWrapperDiv.getAttribute('listener') !== 'true') {
            activeHandlers(buttonWrapperDiv, payload);
        }
    }

    return payload;
};

function activeHandlers(
    buttonWrapperDiv: HTMLElement,
    { successHandler, errorHandler, onClickHandler, readyHandler }: any
) {
    if (!buttonWrapperDiv) {
        console.warn('buttonWrapperDiv is not defined');

        return;
    }

    onClick(buttonWrapperDiv, onClickHandler);
    buttonErrorHandler(buttonWrapperDiv, errorHandler);
    buttonReadyHandler(buttonWrapperDiv, readyHandler);
    orderProcessedHandler(buttonWrapperDiv, successHandler);

    buttonWrapperDiv.setAttribute('listener', 'true');
}

function insertScript(url: string) {
    const { head } = document;

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = url;

    script.setAttribute('data-script', ' ');
    script.setAttribute('data-btn-id', 'solid_pay_btn');
    script.setAttribute('data-overlay', 'true');
    script.setAttribute('data-tittle', 'Tittle for payment widget');
    script.setAttribute('data-description', 'widget.data-description');

    head.insertBefore(script, head.firstChild);
}

function buttonErrorHandler(buttonWrapperDiv: HTMLElement, callback: (error: Error) => void) {
    buttonWrapperDiv.addEventListener(
        'button-error',
        (e: any) => {
            console.error('button-error', e);
            callback(e);
        },
        false
    );
}

function buttonReadyHandler(buttonWrapperDiv: HTMLElement, callback = () => {}) {
    buttonWrapperDiv.addEventListener('button-ready', callback, false);
}

function orderProcessedHandler(buttonWrapperDiv: HTMLElement, callback: (data: any) => void) {
    buttonWrapperDiv.addEventListener(
        'order-processed',
        (e: any) => {
            callback(e.detail.data); //return order, pay_info
        },
        false
    );
}

function onClick(buttonWrapperDiv: HTMLElement, callback = () => {}) {
    buttonWrapperDiv.addEventListener('order-started-processing', callback, false);
}

export default initPaypal;
